import React, { useState } from "react"
import startCase from "lodash/startCase"
import { Helmet } from "react-helmet-async"

import Layout from "../components/layout"
import Seo from "../components/seo/seo"
import ListingPageContent from "../components/listingPageContent/listingPageContent"
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs"
import { parseMarket } from "../consts/markets"
import { useTranslation } from "../helpers/useTranslation"
import { getHreflang, getPagePath } from "../helpers/getHreflang"

// Must match the 'eventLocation' type defined in the backend schema.
const LOCATIONS_KEYS = [
  { key: "Australia" },
  { key: "Asia Pacific" },
  { key: "Europe" },
  { key: "North America" },
  { key: "South America" },
  { key: "UK" },
]

const EventListContent = ({ data, pageContext }) => {
  const [eventSearchKey, setEventSearchKey] = useState(null)

  const { currentPage, totalPages, market, categorySlug } = pageContext
  const { t } = useTranslation()
  const { allSanityEvent } = data
  const { content, seo } = data.sanityEventListing.tabs
  const link = market === "en-us" ? "events" : `${parseMarket(market)}/events`
  const allCategories = data.allSanityEventCategory
  const pagePath = getPagePath()
  const hreflangAbbr = getHreflang()
  const hasLocation = allSanityEvent.nodes.some(event => event.tabs.content.eventLocation !== null)
  const eventSearchKeys = hasLocation ? LOCATIONS_KEYS : []

  const onSearchKeySelect = React.useCallback(value => {
    setEventSearchKey(value?.label ?? null)
  }, [])

  const categories = {
    ...allCategories,
    nodes: [
      ...allCategories.nodes,
      {
        id: "1",
        slug: { current: "past" },
        title: startCase(t("past events")),
        divider: true,
      },
    ],
  }

  const filteredEventsByLocation = React.useMemo(
    () =>
      eventSearchKey
        ? {
            ...allSanityEvent,
            nodes: allSanityEvent.nodes.filter(event => event.tabs.content.eventLocation === eventSearchKey),
          }
        : allSanityEvent,
    [eventSearchKey]
  )

  return (
    <Layout>
      <Helmet>
        <link rel="alternate" href={pagePath} hreflang={hreflangAbbr} />
        <link
          rel="alternate"
          href={
            market === "es"
              ? pagePath.replace("/es/events", "/au/events")
              : market === "en-au"
              ? pagePath.replace("/au/events", "/es/events")
              : market === "en-us"
              ? pagePath.replace("/events", "/au/events")
              : market === "pt"
              ? pagePath.replace("/pt/events", "/events")
              : pagePath
          }
          hreflang={
            market === "es"
              ? "en-au"
              : market === "en-au"
              ? "es"
              : market === "en-us"
              ? "en-au"
              : market === "pt"
              ? "en"
              : "en"
          }
        />
        <link
          rel="alternate"
          href={
            market === "es"
              ? pagePath.replace("/es/events", "/events")
              : market === "en-au"
              ? pagePath.replace("/au/events", "/events")
              : market === "en-us"
              ? pagePath.replace("/events", "/es/events")
              : market === "pt"
              ? pagePath.replace("/pt/events", "/es/events")
              : pagePath
          }
          hreflang={
            market === "es"
              ? "en"
              : market === "en-au"
              ? "en"
              : market === "en-us"
              ? "es"
              : market === "pt"
              ? "es"
              : "en"
          }
        />
        <link
          rel="alternate"
          href={
            market === "es"
              ? pagePath.replace("/es/events", "/pt/events")
              : market === "en-au"
              ? pagePath.replace("/au/events", "/pt/events")
              : market === "en-us"
              ? pagePath.replace("/events", "/pt/events")
              : market === "pt"
              ? pagePath.replace("/pt/events", "/au/events")
              : pagePath
          }
          hreflang={
            market === "es"
              ? "pt"
              : market === "en-au"
              ? "pt"
              : market === "en-us"
              ? "pt"
              : market === "pt"
              ? "en-au"
              : "en"
          }
        />
        <link
          rel="alternate"
          href={
            market === "es"
              ? pagePath.replace("/es/events", "/events")
              : market === "en-au"
              ? pagePath.replace("/au/events", "/events")
              : market === "pt"
              ? pagePath.replace("/pt/events", "/events")
              : pagePath
          }
          hreflang="x-default"
        />
      </Helmet>
      <Seo
        title={content.hero}
        path={pageContext.pagePath}
        breadcrumbs={[
          {
            link,
            title: "Events",
          },
        ]}
        {...seo}
      />
      <Breadcrumbs homePageLabel={t("events")} steps={[{ label: t("events"), path: "" }]} />
      <ListingPageContent
        blogSearchKeys={eventSearchKeys}
        selectPlaceholder="Filter by location"
        title={content.hero}
        subtitle={content.subtitle}
        baseUrl={link}
        categorySlug={categorySlug}
        categories={categories}
        items={filteredEventsByLocation}
        currentPage={currentPage}
        totalPages={totalPages}
        onSearchKeySelect={onSearchKeySelect}
      />
    </Layout>
  )
}

export default EventListContent
